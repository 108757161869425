<template>
  <div class="wrapper">
    <div class="content">
      <h1>POLÍTICA DE PRIVACIDAD</h1>
      <h2>¿Quién es el responsable del tratamiento de sus datos?</h2>
      <p>
        El responsable de los tratamientos de datos referentes a los distintos
        procesos con respecto a la gestión de usuarios, clientes y clientes
        potenciales, colaboradores y proveedores es Solutz Energía, S.L. , con
        domicilio social en C/ Principe de viana 3, 28023, Madrid (provincia de
        Madrid) y CIF B88571518.
      </p>

      <h2>
        ¿Qué tipo de datos tenemos sobre su persona y cómo los hemos conseguido?
      </h2>
      <p>
        Las categorías de datos personales que Solutz Energía, S.L. trata sobre
        sus clientes y proveedores son:
      </p>
      <ul>
        <li>Datos de identificación</li>
        <li>Direcciones postales o electrónicas</li>
        <li>Información comercial</li>
        <li>Datos económicos y de transacciones</li>
        <li>Imagen; Voz</li>
        <li>Datos relativos al consumo energético</li>
      </ul>
      <p>
        Asimismo, le informamos que debido a las características del tratamiento
        de los datos sobre nuestros usuarios, clientes y/o proveedores tratamos
        los siguientes datos de categorías especiales: Datos relativos a la
        salud
      </p>
      <p>
        Todos los datos arriba mencionados los hemos obtenido o directamente de
        Usted mediante la presentación de una oferta comercial, propuesta
        contractual, etc. o mediante su empresa al facilitarnos los datos de
        identificación y demás información necesaria para llevar a cabo el
        objeto de la relación contractual entre las partes o mediante una base
        de datos de carácter público. Será una obligación suya o de su empresa
        facilitarnos los datos actualizados en caso de modificación. Con
        respecto a los datos de candidatos a empleo hemos obtenido sus datos
        mediante la presentación de un CV por su parte o la obtención de su
        candidatura mediante los servicios de un portal de empleo. En este
        último caso Usted ha autorizado dicha cesión de sus datos a nuestra
        empresa al insertar sus datos curriculares en el portal de empleo.
      </p>

      <h2>¿Por cuánto tiempo conservamos sus datos?</h2>
      <p>
        Los datos personales relativos a personas físicas vinculadas a clientes
        potenciales, clientes y proveedores que Solutz Energía, S.L. recopile
        mediante los distintos formularios de contacto y/o recogida de
        información se conservarán mientras no se solicite su supresión por el
        interesado. Los datos proporcionados por nuestros clientes y proveedores
        se conservarán mientras se mantenga la relación mercantil entre las
        partes respetando en cualquier caso los plazos mínimos legales de
        conservación según la materia.
      </p>
      <p>
        En cualquier caso Solutz Energía, S.L. guardará sus datos personales
        durante el período de tiempo que sea razonablemente necesario teniendo
        en cuenta nuestras necesidades de dar respuesta a cuestiones que se
        planteen o resolver problemas, realizar mejoras, activar nuevos
        servicios y cumplir los requisitos que exija la legislación aplicable.
        Esto significa que podemos conservar tus datos personales durante un
        período de tiempo razonable incluso después de que hayas dejado de usar
        nuestros productos o de que hayas dejado de usar esta página web.
        Después de este período, tus datos personales serán eliminados de todos
        los sistemas de Solutz Energía, S.L. .
      </p>

      <h2>
        ¿Con qué finalidad y sobre qué base de legitimación tratamos sus datos?
      </h2>
      <p>
        En Solutz Energía, S.L. tratamos los datos que nos facilitan las
        personas interesadas con el fin de gestionar distintas actividades
        derivadas de procedimientos específicos realizados en materia de ventas,
        servicio post venta, gestión de proveedores, gestión de candidatos,
        calidad de servicios, etc. De esta manera, utilizaremos sus datos para
        llevar a cabo algunas de las siguientes acciones basándonos en la
        respectiva base de legitimación:
      </p>
      <table>
        <tr>
          <th>FINALIDAD DE TRATAMIENTO</th>
          <th>BASE DE LEGITIMACIÓN</th>
        </tr>
        <tr>
          <td>Publicidad digital</td>
          <td>Consentimiento del afectado</td>
        </tr>
        <tr>
          <td>Tratamiento de datos de portal web de esteticistas</td>
          <td>Consentimiento del afectado; Ejecución (pre)contrato</td>
        </tr>
        <tr>
          <td>Procesos de búsqueda, evaluación y selección de candidatos</td>
          <td>Consentimiento del afectado</td>
        </tr>
        <tr>
          <td>
            Gestión contable, fiscal, administrativa y comercial de clientes
          </td>
          <td>Ejecución (pre)contrato</td>
        </tr>
        <tr>
          <td>Gestión fiscal, contable y administrativa con proveedores</td>
          <td>Ejecución (pre)contrato</td>
        </tr>
        <tr>
          <td>
            Gestión de las solicitudes de información que llegan a través del
            formularios web o redes sociales
          </td>
          <td>Consentimiento del afectado</td>
        </tr>
        <tr>
          <td>
            Gestión de los derechos de protección de datos de los interesados
          </td>
          <td>Obligación regulatoria</td>
        </tr>
        <tr>
          <td>Tratamiento de portal web de esteticistas</td>
          <td>Consentimiento del afectado; Ejecución (pre)contrato</td>
        </tr>
      </table>
      <p>
        En cuanto, a la base de legitimación referenciada Usted se encuentra
        obligado a facilitar los datos personales, en el supuesto de que no
        facilite sus datos de carácter personal no se podrá ejecutar su
        contrato, cumplir las obligaciones legales o derivadas de los poderes
        públicos.
      </p>
      <p>
        En base a la información facilitada y para que pueda ofrecerle productos
        y servicios de acuerdo con sus intereses y/o mejorar su experiencia de
        usuario le informamos que elaboramos un “perfil comercial”. No obstante,
        le informamos que no se tomarán decisiones automatizadas en base a dicho
        perfil.
      </p>

      <h2>¿A qué destinatarios se comunicarán sus datos?</h2>
      <p>
        Solutz Energía, S.L. nunca compartirá tus datos personales con ninguna
        empresa tercera que pretenda utilizarlos en sus acciones de marketing
        directo, excepto en el caso de que nos hayas autorizado expresamente a
        ello.
      </p>
      <p>
        Le informamos que podemos facilitar tus datos personales a organismos de
        la Administración Pública y Autoridades competentes en aquellos casos
        que reciba un requerimiento legal por parte de dichas Autoridades o en
        los casos que actuando de buena fe, consideramos que tal acción es
        razonablemente necesaria para cumplir con un proceso judicial; para
        contestar cualquier reclamación o demanda jurídica; o para proteger los
        derechos de o sus clientes y el público en general.
      </p>
      <p>
        Solutz Energía, S.L. puede proporcionar tus datos personales a terceras
        personas (Ej. proveedores de servicios de Internet que nos ayudan a
        administrar nuestra página web o llevar a cabo los servicios
        contratados, empresas de soporte y mantenimiento informático, empresas
        de logística, gestorías y asesoramiento fiscal y contable, etc.). En
        cualquier caso, estas terceras personas deben mantener, en todo momento,
        los mismos niveles de seguridad que Solutz Energía, S.L. en relación a
        tus datos personales y, cuando sea necesario, estarán vinculadas por
        compromisos legales a fin de guardar tus datos personales de forma
        privada y segura, y a fin de utilizar únicamente la información
        siguiendo instrucciones específicas de Solutz Energía, S.L. .
      </p>
      <h2></h2>
      <p>
        Cualquier persona tiene derecho a obtener confirmación sobre si Solutz
        Energía, S.L. está tratando datos personales que le concierne, o no. En
        concreto, las personas interesadas pueden solicitar el derecho de acceso
        a sus datos personales, así como recibirlos en un formato común y
        lectura mecanizada si el tratamiento se efectúa por medios electrónicos
        (derecho de portabilidad).
      </p>
      <p>
        Asimismo, las personas interesadas pueden solicitar el derecho de
        rectificación de los datos inexactos o, en su caso, solicitar su
        supresión cuando, entre otros motivos, los datos ya no sean necesarios
        para los fines que fueron recogidos.
      </p>
      <p>
        Complementariamente, en determinadas circunstancias, los interesados
        podrán solicitar la limitación del tratamiento de sus datos, o en
        determinadas circunstancias y por motivos relacionados con su situación
        particular, los interesados podrán ejercitar su derecho a oponerse al
        tratamiento de sus datos. Solutz Energía, S.L. dejará de tratar los
        datos, salvo por motivos legítimos imperiosos, o el ejercicio o la
        defensa de posibles reclamaciones o en aquellas excepciones establecidas
        en la normativa aplicable.
      </p>
      <p>
        Asimismo, le informamos que tiene derecho a retirar sus consentimientos
        otorgados en cualquier momento, sin que ello afecte a la licitud del
        tratamiento basado en el consentimiento previo a su retirada.
      </p>
      <p>
        Así mismo se informa al Usuario que en cualquier momento puede ejercitar
        los mencionados derechos dirigiéndose por escrito a nosotros utilizando
        los datos de contacto que aparecen en el Apartado 1, ‘Responsable de
        tratamiento’ de la presente política de Protección de Datos y Privacidad
        de Solutz Energía, S.L. ,adjuntando copia de su DNI. Al igual modo,
        puede enviarnos un correo electrónico a la dirección : info@solutz.com
        Usted también tendrá el derecho a presentar una reclamación ante la
        Agencia Española de Protección de datos, especialmente cuando no haya
        obtenido satisfacción en el ejercicio de sus derechos.
      </p>
      <div>
        Agencia Española de Protección de Datos. <br />
        C/ Jorge Juan, 6<br />
        28001 – Madrid<br />
        Telf. 901100099 / 912663517
      </div>
      <h2>Protección de datos de los usuarios de la página web</h2>
      <p>
        De conformidad con el vigente Reglamento (UE) 2016/679, Solutz Energía,
        S.L. informa que los datos de carácter personal de los Usuarios del
        sitio web se tratarán para la actividad del tratamiento indicado en cada
        formulario de recogida de datos de nuestra página web por parte de
        Solutz Energía, S.L. . Dicho tratamiento de sus datos estará amparado en
        su propio consentimiento. Al pulsar el botón “ENVIAR”, el Usuario
        consiente al tratamiento de sus datos por parte de Solutz Energía, S.L.
        .
      </p>
      <p>
        Asimismo, le informamos que salvo obligación legal o consentimiento
        expreso por su parte Solutz Energía, S.L. no va a ceder sus datos a
        terceras personas.
      </p>
      <p>
        Igualmente, se informa al Usuario que en cualquier momento puede
        ejercitar los derechos de acceso, rectificación o supresión de datos así
        como disponer de otros derechos reconocidos en el presente documento y
        regulados en el Reglamento (UE) 2016/679 , notificándolo a Solutz
        Energía, S.L. , C/ Principe de viana 3, 28023, Madrid (provincia de
        Madrid) , e-mail: info@solutz.com
      </p>
      <p>
        Por otro lado, de acuerdo con lo dispuesto en la Ley 34/2002, de 11 de
        Julio, de Servicios de la Sociedad de Información y de Comercio
        Electrónico, Solutz Energía, S.L. se compromete a no enviar publicidad a
        través del correo electrónico sin haber recabado antes la expresa
        autorización del destinatario. El Usuario podrá oponerse al envío de
        publicidad marcando la casilla correspondiente.
      </p>

      <h2>Otra información de interés sobre nuestra política de privacidad</h2>
      <h3>Medidas de Seguridad</h3>
      <p>
        Solutz Energía, S.L. adopta los niveles de seguridad requeridos por la
        normativa Europea y Española vigente en protección de datos teniendo en
        cuenta el estado de la técnica, los costes de aplicación y la
        naturaleza, el alcance, el contexto y los fines del tratamiento
        descritos, así como los riesgos de probabilidad y gravedad variables
        para sus derechos y libertades como persona.
      </p>
      <h3>Tratamiento de datos de menores</h3>
      <p>
        Al amparo del RGPD (UE) 2016/679 y de la LO 3/2018, de 5 de diciembre,
        de Protección de Datos Personales y Garantía de los Derechos Digitales,
        los menores de más de 14 años pueden dar su consentimiento a la
        contratación de servicios de la sociedad de la información, como puede
        ser la inscripción en un foro, la cumplimentación de un formulario de
        contacto, etc. No obstante, será responsabilidad de Solutz Energía, S.L.
        comprobar la veracidad de la edad indicada por parte del menor. De la
        capacidad de consentimiento otorgada a los menores de más de 14 años, se
        exceptúan los supuestos en que la ley exija la asistencia de los
        titulares de la patria potestad o tutela para la celebración del acto o
        negocio jurídico en cuyo contexto se recaba el consentimiento para el
        tratamiento. Para el tratamiento de datos de menores de 14 años dicha
        recogida de datos se realizará siempre bajo el expreso consentimiento de
        los padres o tutores legales.
      </p>
      <h3>
        Modificaciones de nuestra Política de Protección de Datos y de
        Privacidad
      </h3>
      <p>
        Ocasionalmente, Solutz Energía, S.L. podrá realizar modificaciones y
        correcciones en este apartado de Política de Protección de Datos para
        Clientes, Proveedores y Usuarios. Por favor, verifique este apartado
        regularmente para consultar los cambios que puedan haber existido y de
        qué manera le pueden afectar.
      </p>
      <h3>
        ¿Por qué es necesario aceptar está Política de Protección de Datos y de
        Privacidad?
      </h3>
      <p>
        Este apartado de Política de Protección de Datos para Clientes,
        Proveedores y Usuarios le proporciona de un modo fácilmente accesible
        toda la información necesaria para que puedas conocer la tipología de
        datos que Solutz Energía, S.L. mantiene sobre sus clientes potenciales,
        clientes y/o proveedores, las finalidades perseguidas, los derechos que
        la normativa de protección de datos reconoce a Ud. como persona afectada
        y el modo de cómo ejercer dichos derechos. Por lo tanto, con el envío
        deliberado de sus datos personales mediante nuestros medios de contacto
        y/o con el inicio de la relación mercantil con nuestra empresa
        consideramos que reconoce y acepta el tratamiento de tus datos
        personales tal y como se describe en la presente política. Esta
        información personal solamente se usará para los fines para los cuales
        nos la has facilitado o determinadas normativas nacionales o regionales
        nos habilitan hacerlo. En cualquier caso, debemos advertirle que una
        negativa por su parte para facilitarnos determinados datos solicitados
        podría obstaculizar el desarrollo de la relación contractual entre las
        partes con posibles consecuencias serias al momento de prestar las
        diversas prestaciones contempladas dentro del contrato mercantil
        celebrado con la parte contratante. Si tienes cualquier pregunta en
        relación a este apartado de Política de Protección de Datos para los
        Clientes Potenciales, Clientes y Proveedores de Solutz Energía, S.L. ,
        por favor, póngase en contacto con la empresa usando la dirección
        facilitada en la Apartado primero ‘Responsable de Tratamiento’ y
        estaremos encantados de atenderte y dar respuesta a las cuestiones
        adicionales que nos quieras plantear.
      </p>
      <h2>Legislación aplicable</h2>
        <p>Las presentes Condiciones se regirán en todo momento por lo dispuesto en la legislación española y europea en materia de protección de datos de carácter personal y de privacidad.</p>
      <div style="height:50px"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  mounted() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrapper {
  .content {
    text-align: left;
  }
  table {
    margin-top: 30px;
    overflow-x: auto;
    td {
      text-align: center;
      padding: 8px;
      box-shadow: 0px 2px 3px 0px rgba($color: #000000, $alpha: 0.2);
      // border: 1px solid black;
      // border-bottom: 1px solid black;
    }
    th {
      text-align: center;
      padding: 8px;
      background-color: lightgray;
      box-shadow: 0px 2px 3px 0px rgba($color: #000000, $alpha: 0.2);
    }
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
  }
}
</style>
